import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';



import Navigation from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import HowWeWork from '../containers/howWeWork.js';
import NewsSlider from '../containers/newsComponent.js';




import ilustracija from '../images/Ilustracija.png';
import quality from '../images/quality.svg';
import austria from '../images/austria.svg';
import mouseScroll from '../images/mouseScroll.svg';
import vertikalnaS from '../images/vertikalnaS.svg';
import horizontalnaS from '../images/horizontalnaS.svg';
import sOprema from '../images/sOprema.svg';
import zastitnaO from '../images/zastitnaO.svg';
import projektovanje from '../images/projektovanje.svg';

import lupa from '../images/lupa.png';

import slider1 from '../images/slider.png';
import slider2 from '../images/slider2.png';

import slika1 from '../images/gallery/slika1.png';
import slika2 from '../images/gallery/slika2.png';
import slika3 from '../images/gallery/slika3.png';
import slika4 from '../images/gallery/slika4.png';
import slika5 from '../images/gallery/slika5.png';
import slika6 from '../images/gallery/slika6.png';
import slika7 from '../images/gallery/slika7.png';
import slika8 from '../images/gallery/slika8.png';



import {
    Container,
    Row,
    Col,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,

} from 'reactstrap';
import SliderComponent from '../containers/sliderComponent.js';

const items = [
    {
        src: slika1
    },
    {
        src: slika2
    },
    {
        src: slika3
    },
    {
        src: slika4
    },
    {
        src: slika5
    },
    {
        src: slika6
    },
    {
        src: slika7
    },
    {
        src: slika8
    },

];

export default class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            progress: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            activeIndex: 0
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }



    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {
            let currScroll = window.pageYOffset;
            let offsetTop = 0;

            if (this.props[0].location.pathname === '/section1' && this.section1) {
                offsetTop = this.section1.offsetTop;
            }


            if (currScroll > offsetTop) {
                for (let i = currScroll; i >= offsetTop; i -= 20) {
                    window.scrollTo(0, i);
                    await this.sleep((2000 / offsetTop / 20));
                }

            } else {
                for (let i = currScroll; i < offsetTop; i += 20) {
                    window.scrollTo(0, i);
                    await this.sleep((2000 / offsetTop / 20));
                }
            }
        }
    }



    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.scrollTo(0, 0);



    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {
        const { activeIndex } = this.state;
        const slider = items.map((item) => {
            return (
                <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item.src}>
                    <img src={item.src} className="img-fluid" />
                </CarouselItem>
            );
        });
   
        return (
            <div>
                <div className="wrappe_nav">
                    <Navigation></Navigation>
                </div>
                <div className="header" >
                    <Container>
                        <Row>
                            <Col xl={{size: 6, order: 0}} lg={{size: 6, order: 0}} md={{size: 6, order: 0}}   xs={{size: 12, order: 1}}>
                                <Isvg src={austria} />
                                <Isvg src={quality} />
                                <h3><span>PROIZVODNJA<br></br>
                                    I PRODAJA</span> SAOBRAĆAJNE
                    SIGNALIZACIJE I OPREME</h3>

                                <p>Kao Vaš pouzdan partner, PRO VIA je uvijek spremna
                    da odgovori na sve Vaše zahtjeve</p>

                                <Link to="#"><Button>KONTAKTIRAJTE NAS</Button></Link>

                            </Col>
                            <Col xl={{size: 6, order: 1}} lg={{size: 6, order: 1}} md={{size: 6, order: 1}}   xs={{size: 12, order: 0}}>
                                <img src={ilustracija} className="img-fluid" />
                            </Col>
                        </Row>

                    </Container>
                </div>
                <div className="button d-flex justify-content-center">
                    <Link to="/section1"><Button></Button></Link>
                </div>

                <div className="whatWeDo" ref={(node) => this.section1 = node}>
                    <h4>Čime se bavimo?</h4>
                    <h1>Saobraćajna<span> signalizacija i oprema</span> puteva</h1>
                    <p>je oblast na koju smo fokusirani i za koju smo specijalizovani kako u proizvodnji,<br></br>
                        tako i u njenom razvoju i projektovanju.</p>

                    <Container>
                        <Row>
                            <Col xl="4" xs="12" md="6" lg="4">
                                <div className="box">
                                    <div className="content">
                                        <Isvg src={vertikalnaS} />
                                        <h4>VERTIKALNA<br></br>
                                        SIGNALIZACIJA</h4>
                                    </div>
                                </div>
                            </Col>

                            <Col xl="4" xs="12" md="6" lg="4">
                                <div className="box">
                                    <div className="content">
                                        <Isvg src={horizontalnaS} />
                                        <h4>HORIZONTALNA<br></br>
                                        SIGNALIZACIJA</h4>
                                    </div>
                                </div>
                            </Col>

                            <Col xl="4" xs="12" md="6" lg="4">
                                <div className="box">
                                    <div className="content">
                                        <Isvg src={sOprema} />
                                        <h4>SAOBRAĆAJNA<br></br>
                                        OPREMA</h4>
                                    </div>
                                </div>
                            </Col>


                            <Col xl="4" xs="12" md="6" lg="4">
                                <div className="box">
                                    <div className="content">
                                        <Isvg src={zastitnaO} />
                                        <h4>ZAŠTITNA<br></br>
                                        ČELIČNA OGRADA</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="4" xs="12" md="6" lg="4">
                                <div className="box">
                                    <div className="content">
                                        <Isvg src={projektovanje} />
                                        <h4>PROJEKTOVANJE<br></br>
                                        I KONSALTING</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="4" xs="12" md="6" lg="4">
                                <div className="box color">
                                    <div className="content ">
                                        <Link to="/ourServices"><h4 className="no-pa-up">Detaljnije</h4></Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>


                </div>

            <HowWeWork></HowWeWork>

                <div className="gallery">
                    <Container>
                        <div className="heading">
                            <h1><span>Galerija</span> naših radova</h1>
                            <p>Pogledajte neke od naših radova</p>

                            <Link to="/gallery"><Button>KOMPLETNA GALERIJA</Button></Link>
                        </div>
                        <div className="galerija">

                            <Row>
                                <Col xl="6" xs="12" lg="6" md="6">
                                    <Row>
                                        <Col xl="6" xs="12" lg="6" md="6">
                                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 0 })}>
                                                <img src={slika1} className="img-fluid slika" />
                                                <div className="overlay">
                                                    <img src={lupa} />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xl="6" xs="12" lg="6" md="6">
                                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 1 })}>
                                                <img src={slika2} className="img-fluid slika" />
                                                <div className="overlay">
                                                    <img src={lupa} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 2 })}>
                                        <img src={slika4} className="img-fluid slika" />
                                        <div className="overlay">
                                            <img src={lupa} />
                                        </div>
                                    </div>

                                </Col>

                                <Col xl="6" xs="12" lg="6" md="6">
                                    <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 3 })}>

                                        <img src={slika3} className="img-fluid slika" />
                                        <div className="overlay">
                                            <img src={lupa} />
                                        </div>
                                    </div>

                                    <Row>
                                        <Col xl="6" xs="12" lg="6" md="6">
                                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 4 })}>

                                                <img src={slika5} className="img-fluid slika" />
                                                <div className="overlay">
                                                    <img src={lupa} />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xl="6" xs="12" lg="6" md="6">
                                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 5 })}>

                                                <img src={slika6} className="img-fluid slika" />
                                                <div className="overlay">
                                                    <img src={lupa} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xl="6" xs="12" lg="6" md="6">
                                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 6 })}>

                                                <img src={slika7} className="img-fluid slika" />
                                                <div className="overlay">
                                                    <img src={lupa} />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xl="6" xs="12" lg="6" md="6">
                                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 7 })}>
                                                <img src={slika8} className="img-fluid slika" />
                                                <div className="overlay">
                                                    <img src={lupa} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                    
                            </Row>
                        </div>
                        {this.state.opened ?
                <div className="lightbox">
                  <Container>
                    <Row>
                      <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                      <Carousel className="bg"
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}
                      >
                        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                        {slider}
                        <i className="fa fa-angle-left" onClick={this.previous}></i>
                        <i className="fa fa-angle-right" onClick={this.next}></i>
                      </Carousel>
                    </Row>
                  </Container>
                </div> : null
              }
                    </Container>

                </div>
    
                <div className="news">
                    <Container>
                        <h1><span>Novosti</span> i obavještenja</h1>
                        <p>Aktuelnosti vezane za našu firmu</p>
                  
<div className = "test">
<SliderComponent></SliderComponent>
</div>

                    </Container>

                </div>


                            <Map {...this.props}></Map>

                          
                 
                <Footer></Footer>
            </div >
        )
    }

}

