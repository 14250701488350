import React from 'react';
import {Link} from 'react-router-dom';


import Navigation from '../containers/navbar.js';
import HowWeWork from '../containers/howWeWork.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';

import onama1 from '../images/onama1.png';
import onama2 from '../images/onama2.png';
import dotsl from '../images/dotsl.png';
import dotsr from '../images/dotsr.png';
import Ilustracija from '../images/Ilustracija.png';


import {
    Col,
    Row,
    Container,
    Button,
    Breadcrumb,
    BreadcrumbItem
} from 'reactstrap';



export default class AboutUs extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <>
                <div className="wrapp_nav">
                    <Navigation></Navigation>
                </div>

       <div className="wrapp">
            <Container>
            <div className="heading">
                <h1>O NAMA</h1>

            </div>
            <div className="position">
            <h3><Link to="/">Početna&nbsp;&nbsp;/&nbsp;&nbsp;</Link>O nama</h3>
            </div>
            </Container>

        </div> 
        
                <div className="about_Section1">
                    <Container>
                    <h4>Čime se bavimo?</h4>
                    <h1>Saobraćajna<span> signalizacija i oprema</span> puteva</h1>
                        <div className="content">
                    <Row>
                        <Col xl="5" lg="5" xs="12">
                            <div className="LeftDots">
                        <img src={onama1} className="img-fluid slika"/>
                        <img src={dotsl} className="img-fluid dotsl"/>
                        </div>

                        </Col>
                        <Col xl="7" lg="7" xs="12">
                            <p>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                            sed diam nonumy eirmod tempor invidunt ut labore et dolore
                             magna aliquyam erat, sed diam voluptua. At vero eos et accusam 
                             et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                              no sea takimata sanctus est Lorem ipsum dolor sit amet.
                            </p>
                            
            
                            <p>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                            sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                             sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                            </p>
                            </Col>
                    </Row>
                    </div>
                    <Row>
                        <Col xl="7" lg="7" xs="12">
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                                sed diam nonumy eirmod tempor invidunt ut labore et dolore
                                 magna aliquyam erat, sed diam voluptua. At vero eos et
                                  accusam et justo duo dolores et ea rebum. Stet clita 
                                  kasd gubergren, no sea takimata sanctus est Lorem 
                                  ipsum dolor sit amet.</p>
                                
                                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                   sed diam nonumy eirmod tempor invidunt ut labore 
                                   et dolore magna aliquyam erat, sed diam voluptua.
                                    At vero eos et accusam et justo duo dolores et ea rebum.
                                     Stet clita kasd gubergren, no sea takimata sanctus est 
                                     Lorem ipsum dolor sit amet.
                                  </p>

                        </Col>
                        <Col xl="4" lg="5" xs="12" className="offset-xl-1">
                            <div className="RightDots">
                            <img src={onama2} className="img-fluid slika"/>
                            <img src={dotsr} className="img-fluid dotsr"/>
                            </div>
                        </Col>
                    </Row>
                
                    </Container>
                </div>
             
                <HowWeWork></HowWeWork>
                <Map {...this.props}></Map>
                <Footer></Footer>
            </>
        );
    }
}