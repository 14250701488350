import React from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import logo from '../images/logo.png';
import phone from '../images/phone.svg';
import background from '../images/sub1.png';
import insta_icon from '../images/instagram.svg';
import face_icon from '../images/facebook.svg';
import nav from '../images/nav.png';
import Headroom from 'react-headroom';


import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  NavLink
} from 'reactstrap';


export default class navbar extends React.Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.onScroll = this.onScroll.bind(this);
    this.state = {
      isTop: true
    };

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      width: '',
      width: 0, height: 0

    };

  }

  
  onScroll(isTop) {
    this.setState({ isTop });
    
  }


  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY > 1;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
      
    });

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <div>
        <div className="ss">
          <img src={background} className="img-fluid bg" />
          <img src={nav} className="img-fluid nav" />
        </div>
        {this.state.width <= 767 ?
          <Headroom >
            <div className="wrapper">

              <div className="phone">
                <Isvg src={phone} />
                <p>+387 65 008-988</p>
              </div>
              <div className="socialMedia">
                <p>Pratite nas:</p>
                <Isvg src={face_icon} />
                <Isvg src={insta_icon} />
              </div>

            </div>

            <Navbar className="navbar" expand="lg">
              <Container>

                <NavbarBrand href="/"><img src={logo} className=" log" /> </NavbarBrand>

                <NavbarToggler onClick={this.toggle}><i className="fa fa-bars"></i></NavbarToggler>

                <Collapse isOpen={this.state.isOpen} navbar >

                  <div className="ml-auto">
                    <Nav navbar>
                      <NavItem>
                        <NavLink to="/" exact tag={RRNavLink} activeClassName="activ" >POČETNA</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink to="/about" exact tag={RRNavLink} activeClassName="activ" >O NAMA</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink to="/newsPage" exact tag={RRNavLink} activeClassName="activ" >SERTIFIKATI</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink to="/gallery" exact tag={RRNavLink} activeClassName="activ" >GALERIJA</NavLink>
                      </NavItem>
                      <NavItem className="no-ma-right">
                        <NavLink to="/contact" exact tag={RRNavLink} activeClassName="activ" >KONTAKT</NavLink>
                      </NavItem>

                    </Nav>
                  </div>
                </Collapse>

              </Container>
            </Navbar>
          </Headroom>
          :
          <div className = "aaa">
          <Navbar className={!this.state.isTop ? "navbar" : "navbar down"} expand="lg">
            <Container>

              <NavbarBrand href="/"><img src={logo} className=" log" /> </NavbarBrand>

              <NavbarToggler onClick={this.toggle}><i className="fa fa-bars"></i></NavbarToggler>

              <Collapse isOpen={this.state.isOpen} navbar >

                <div className="ml-auto">
                  <div className="wrapper">
                    <div className="phone">
                      <Isvg src={phone} />
                      <p>+387 65 008-988</p>
                    </div>
                    <div className="socialMedia">
                      <p>Pratite nas:</p>
                      <Isvg src={face_icon} />
                      <Isvg src={insta_icon} />
                    </div>
                  </div>


                  <Nav navbar>

                    <NavItem>
                      <NavLink to="/" exact tag={RRNavLink} activeClassName="activ" >POČETNA</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/about" exact tag={RRNavLink} activeClassName="activ" >O NAMA</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/newsPage" exact tag={RRNavLink} activeClassName="activ" >SERTIFIKATI</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/gallery" exact tag={RRNavLink} activeClassName="activ" >GALERIJA</NavLink>
                    </NavItem>
                    <NavItem className="no-ma-right">
                      <NavLink to="/contact" exact tag={RRNavLink} activeClassName="activ" >KONTAKT</NavLink>
                    </NavItem>

                  </Nav>
                </div>
              </Collapse>

            </Container>
          </Navbar>
          </div>
        }
      </div>
    );
  }
}