import React from 'react';
import Isvg from 'react-inlinesvg';
import {Link} from 'react-router-dom';

import Navigation from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import News from '../containers/newsComponent.js';


import slider1 from '../images/slider.png';
import slider2 from '../images/slider2.png';
import {
    Container,
    Col,
    Row
} from 'reactstrap';
const articles = [
    {
        _id: '1',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 1',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider1,
        link: '/singleNews'
    },
    {
        _id: '2',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 2',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/singleNews'
    },
    {
        _id: '3',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 3',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider1,
        link: '/test'
    },    {
        _id: '4',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 4',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/test'
    }
];
export default class NewsPage extends React.Component{
    constructor(props){
    super(props);
}
render(){
    return(
        <>
                        <div className="wrapp_nav">
                    <Navigation></Navigation>
                </div>

       <div className="wrapp">
            <Container>
            <div className="heading">
                <h1>NOVOSTI</h1>

            </div>
            <div className="position">
            <h3><Link to="/">Početna&nbsp;&nbsp;/&nbsp;&nbsp;</Link>Novosti</h3>
            </div>
            </Container>

        </div> 
        <div className="about_Section1">
                    <Container>
                    <h1><span>Aktuelnosti</span> iz naše firme</h1>

                    <p>Aktuelnosti vezane za našu firmu</p>
                    </Container>
                    </div>

                    <News items = {articles}></News>
                    

                   <Map {...this.props}></Map>
                   <Footer></Footer>
        </>
    );
}
}