import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import f from '../images/footer.png';
import logoFooter from '../images/logoFooter.svg';
import face_icon from '../images/facebook.svg';
import insta_icon from '../images/instagram.svg';
import phone from '../images/phoneRed.svg';
import time from '../images/time.svg';




import {
    Col,
    Row,
    Container,
    Button,
    NavItem

} from 'reactstrap';


export default class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="footer">
                  
                    <Container>
                      
                        <div className="aa d-flex justify-content-end">
                            <p>Pratite nas: <Isvg src={insta_icon} /> <Isvg src={face_icon} /></p>
                        </div>
                        <div className="content">
                         
                            
                            <Row>
                                <Col xl="6">
                                    <img src={logoFooter} className="img-fluid logoFoter" />
                               

                                </Col>
                                <Col xl="6" >

                                    <Row>

                                        <NavItem>
                                            <Link to="/">POČETNA</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="#">O nama</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="#">SERTIFIKATI</Link>
                                        </NavItem>



                                        <NavItem>
                                            <Link to="#">GALERIJA</Link>
                                        </NavItem>

                                        <NavItem className="no-ma-right">
                                            <Link to="#">Kontakt</Link>
                                        </NavItem>

                                    </Row>


                                </Col>

                            </Row>
                            <Row>
                                <Col xl="6">
                                    <p>PRO VIA d.o.o. Bijeljina osnovano je<br></br>
                                        2008. godine sa osnovnom djelatnošću<br></br>
                                        proizvodnja, ugradnja i promet saobraćajne<br></br>
                                        signalizacije i opreme puteva.</p>
                                </Col>
                                <Col xl="6">
                                    <Row>
                                        <Col xl="6" xs="6">
                                            <div className="phone">
                                                <Isvg src={phone} className="img-fluid" />
                                                <h4>Telefon:</h4>
                                                <p><span>+387 65 008-988</span></p>
                                            </div>
                                        </Col>
                                        <Col xl="5" xs="6">
                                            <div className="time">
                                                <Isvg src={time} className="img-fluid" />
                                                <h4>Radno vrijeme:</h4>
                                                <p><span>PON - PET</span></p>
                                                <p><span>08:00 -17:00 h</span></p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                           
                        </div>
                        <div className="copyright">

                            <Row>
                                <Col xl="6" lg="6" md="8" sm="12" xs="12">
                                    <p>Copyright @ ProVia d.o.o.- 2019. All Right Reserved.</p>
                                </Col>
                                <Col xl="3" lg="3" md="4" sm="12" xs="12" className="offset-xl-3 offset-lg-3 d-flex justify-content-xl-end justify-content-sm-start bla">
                                    <p>Created by <span><a href="https://www.novamedia.agency/" target="_blank">NOVA media</a></span></p>
                                </Col>
                            </Row>

                        </div>
                    </Container>

                </div>
            </div>
        );
    }
}