import React from 'react';
import {Link} from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import Navigation from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer';

import phone_icon from '../images/phone_icon.svg';
import email_icon from '../images/email.svg';


import {
    Col, Container, Button, Row, Input, Label, FormGroup, NavItem, NavLink
} from 'reactstrap';


export default class Contact extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <>
                            <div className="wrapp_nav">
                    <Navigation></Navigation>
                </div>

       <div className="wrapp">
            <Container>
            <div className="heading">
                <h1>O NAMA</h1>

            </div>
            <div className="position">
            <h3><Link to="/">Početna&nbsp;&nbsp;/&nbsp;&nbsp;</Link>Kontakt</h3>
            </div>
            </Container>

        </div> 
        <div className="about_Section1">
                    <Container>
                    <h1><span>Kontaktirajte</span> nas</h1>
                    <p>Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.</p>
                    </Container>
                    </div>
<div className="contact">
<Container>
                   
                        <Row>
                            <Col xs="12" xl="8" md="7" lg="7">

                                <Row>
                                    <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                        <FormGroup>
                                            <Input type="text" name="" id="" placeholder="Ime" />
                                            <Label for="ime">VAŠE IME</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                        <FormGroup>
                                            <Input type="text" name="" id="" placeholder="Prezime" />
                                            <Label for="prezime">VAŠE PREZIME</Label>
                                        </FormGroup>

                                    </Col>
                                    <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                        <FormGroup>
                                            <Input type="text" name="" id="" placeholder="Telefon" />
                                            <Label for="email">VAŠ BROJ TELEFONA</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                        <FormGroup>
                                            <Input type="text" name="" id="" placeholder="E-mail" />
                                            <Label for="telefon">VAŠA E-MAIL ADRESA</Label>
                                        </FormGroup>

                                    </Col>

                                </Row>

                                <FormGroup>
                                    <Input type="text" name="" id="naslov" placeholder="Naslov" />
                                    <Label for="naslov">SUBJECT</Label>
                                </FormGroup>

                                <FormGroup>
                                    <Input type="textarea" id="textarea" placeholder="Vaša poruka" />
                                    <Label for="textarea" >PORUKA</Label>

                                </FormGroup>

                                <Button >POŠALJI</Button>
                            </Col>
                            <Col xl="4" xs="12" md="5" lg="5" sm="12">
                                <div className="contancInfo">
                                    <div className="content">
                                        <h2>INFORMACIJE</h2>

                                        <h3><span>PRO VIA d.o.o.</span><br/>
                                        Stefana Dečanskog<br/>
                                        ATC, lamela B/15</h3>
                                        <br/>
                                       <h3> 76300 Bijeljina<br/>
                                        Republika Srpska, BiH</h3>

                                        <div className="contantDetail">
                                        <h3><Isvg src={phone_icon}/> +387 65 008 988</h3>
                                        <h3><Isvg src={email_icon}/> proviadoo@gmail.com</h3>
                                        </div>
                                        
                                        <h4>RADNO VRIJEME</h4>

                                        <p><span>Ponedeljak - Petak</span></p>
                                        <p>08:00 -20:00</p>



                                        

                               

                                        
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    </div>

        <Map {...this.props}></Map>
        <Footer></Footer>
            </>
        );
    }
}