import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import Home from './views/home.js';
import About from './views/about.js';
import Gallery from './views/gallery.js';
import Contact from './views/contact.js';
import NewsPage from './views/newsPage.js';
import SingleNews from './views/singleNews.js';
import OurServices from './views/ourServices.js';

import './App.css';
import GoogleMap from './googleMapScript.js';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    window.googleMapsCallback = this.callback;
    this.state = {};
  }
  callback() {
    this.setState({ mapinit: true });
  }

  render() {
    return (
      <div>
         <GoogleMap API_KEY="AIzaSyByPzC2VWMbbvAyR3MgBZktkV3gc-nc3rY" />
        <Router>
          <Switch>
          <Route exact path="/" render={(...renderProps) => (<Home {...renderProps} {...this.state} ></Home>)} />
          <Route exact path="/section1" render={(...renderProps) => (<Home {...renderProps} {...this.state} ></Home>)} />
          <Route exact path="/about" render={(...renderProps) => (<About {...renderProps} {...this.state}></About>)}/>
          <Route exact path="/gallery" render={(...renderProps) => (<Gallery {...renderProps} {...this.state}></Gallery>)}/>
          <Route exact path="/contact" render={(...renderProps) => (<Contact {...renderProps} {...this.state}></Contact>)}/>
          <Route exact path="/newsPage" render={(...renderProps) => (<NewsPage {...renderProps} {...this.state}></NewsPage>)}/>
          <Route exact path="/singleNews" render={(...renderProps) => (<SingleNews {...renderProps} {...this.state}></SingleNews>)}/>
          <Route exact path="/ourServices" render={(...renderProps) => (<OurServices {...renderProps}{...this.state}></OurServices>)}/>

          </Switch>
        </Router>
      </div>
    )
  }
}

export default App;
