import React from 'react';
import { Link } from 'react-router-dom';

import Navigation from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import NewsComponent from '../containers/newsComponent.js';

import slider1 from '../images/slider.png';
import slider2 from '../images/slider2.png';
import news from '../images/news.png';


import {
    Container,
    Col,
    Row
} from 'reactstrap';
const articles = [
    {
        _id: '1',
        allias: 'sdsd',
        title: 'Naslov prve vijesti',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider1,
        link: '/singleNews',
        line: '<hr/>'
    },
    {
        _id: '2',
        allias: 'sdsd',
        title: 'Naslov prve vijesti',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/test'
    },
    {
        _id: '3',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 3',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider1,
        link: '/test'
    }, {
        _id: '4',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 4',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/test'
    }
];
export default class singleNews extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const singleNews = 'singleNews';
        return (
            <>
                <div className="wrapp_nav">
                    <Navigation></Navigation>
                </div>

                <div className="wrapp">
                    <Container>
                        <div className="heading">
                            <h1>NOVOSTI</h1>

                        </div>
                        <div className="position">
                            <h3><Link to="/">Početna&nbsp;&nbsp;/&nbsp;&nbsp;</Link>Novosti</h3>
                        </div>
                    </Container>


                </div>
                <div className="about_Section1">
                    <Container>
                        <h1><span>Naslov prve vijesti</span></h1>

                    </Container>
                </div>
                <div className="singlePage">
                    <Container>

                        <Row >
                            <Col xl="8">
                                <div className="headText">
                                <h3>18.06.2019. god.  |  21:45 h</h3>
                                <p>Kao Vaš pouzdan partner, PRO VIA je uvijek spremna da odgovori
                                na sve Vaše zahtjeve.  Kao Vaš pouzdan partner, PRO VIA je uvijek
                                spremna da odgovori na sve Vaše zahtjeve. </p>
                                </div>
                                <img src={news} className="img-fluid detailImage" />
                                <br></br>
                                <br></br>


                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam 
                                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                                sed diam voluptua. At vero eos et accusam et justo duo dolores et ea 
                                rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
                                ipsum dolor sit amet.
                                </p>
                                <br></br>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam 
                                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                                sed diam voluptua. At vero eos et accusam et justo duo dolores et ea 
                                rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
                                ipsum dolor sit amet.
                                </p>
                                <br></br>

                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam 
                                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                                sed diam voluptua. At vero eos et accusam et justo duo dolores et ea 
                                rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
                                ipsum dolor sit amet.
                                </p>
                            </Col>
                            <Col xl="4" >
                                <div className="wrapp_news">
                                    <NewsComponent singleNews={singleNews} items={articles.slice(0, 2)}></NewsComponent>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>

                <Map {...this.props}></Map>

                <Footer></Footer>
            </>
        );
    }
}