import React from 'react';
import {Link} from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import slider1 from '../images/slider.png';


import {
    Container,
    Col,
    Row,
    Button
} from 'reactstrap';

export default class NewsItem extends React.Component{
    constructor(props){
        super(props);
    }
    render(){

        return(
            <>
        
             
            
                          <div className="slide">
                                  <Link to="#">  <img src={this.props.image} className="img-fluid" /></Link>
                                    <div className="content">
                                     
                                        <div className="date">
                                            <h2>{this.props.date}</h2>
                                            <p>{this.props.month}</p>
                                        </div>
                                        <div className="text">
                                       <Link to="#">    
                                        <h4>{this.props.title}</h4></Link>
                                        

                                            <p>{this.props.description}</p>
                                            <Link to={this.props.link}>  <h6><Button ></Button>Detaljnije</h6></Link>
                                         
                                        </div>
                                    </div>
                                
                                </div>
                               
                            
                            
                            
            </>
        );
    }
}