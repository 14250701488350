import React from 'react';
import mapIcon from '../images/pinLocation.svg';
import onMap from '../images/onmap.png';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.state = {};
    }

    initMap() {
        console.log("InitMAP");
        this.setState({
            _mapInit: true
        });
        var latLng = new window.google.maps.LatLng(44.987801, 17.917206);

        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 18,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "gestureHandling",

        });


        var marker = new window.google.maps.Marker({
            position: latLng,
            map: map,
            icon: mapIcon
        });

    }

    componentDidMount() {
        if (this.props.mapinit && !this.state._mapInit) {
            this.initMap();
        }

    }

    componentDidUpdate() {
        if (this.props.mapinit && !this.state._mapInit) {
            this.initMap();
        }
    }

    render() {
        return (
            <div>
                
                <div className="location">

                    <Container>
                        <div className="heading">
                            <Row>

                                <Col xl="6">
                                    <h1><span>Naša lokacija</span></h1>

                                    <p>Posjetite nas u Bijeljini</p>
                                </Col>
                                <Col xl="6">
                                    <p className="pp">PRO VIA d.o.o. Bijeljina osnovano je 2008. godine
                                    sa osnovnom djelatnošću – proizvodnja, ugradnja i promet
                        saobraćajne signalizacije i opreme puteva</p>
                                </Col>
                            </Row>
                        </div>

                        <div className="mapa">
                {
                    this.props.mapinit ?
                        <div className="map" ref={(input) => { this.GoogleMap = input; }}>
                         
                           
                        </div>
                        : null
                }
                   <div className="onMap">
                                <img src={onMap} className="img-fluid" />
                                <p><span>PRO VIA d.o.o.</span> <br></br>
                                    Stefana Dečanskog<br></br>
                                    ATC, lamela B/15</p>

                                <p>
                                    76300 Bijeljina <br></br>
                                    Republika Srpska, BiH
                                    </p>
                            </div>

            </div>
            </Container>
            </div>
            </div>
        );
    }
}
