import React from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import Navigation from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';

import vertikalna from '../images/ourservice/vertiaklnaSignalizacija.svg';
import horizontalna from '../images/ourservice/horizontalnaSignalizacija.svg';
import saobracajnaO from '../images/ourservice/saobracajnaOprema.svg';
import zastitnaO from '../images/ourservice/zastitnaOgrada.svg';
import projektovanje from '../images/ourservice/projektovanje.svg';

import znak from '../images/ourservice/znak.png';
import dots from '../images/ourservice/dots.png';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

export default class ourServices extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="wrapp_nav">
                    <Navigation></Navigation>
                </div>

                <div className="wrapp">
                    <Container>
                        <div className="heading">
                            <h1>NAŠE USLUGE</h1>

                        </div>
                        <div className="position">
                            <h3><Link to="/">Početna&nbsp;&nbsp;/&nbsp;&nbsp;</Link>Usluge</h3>
                        </div>
                    </Container>

                </div>


                <div className="ourServices">
                    <Container>
                        <Row>
                            <Col xl="4" lg="4" md="4">
                                <div className="box activ">
                                    <div className="content">
                                        <Isvg src={vertikalna} />
                                        <div className="heding">
                                        <h3>VERTIKALNA SIGNALIZACIJA</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="content">
                                        <Isvg src={horizontalna} />
                                        <div className="heding">
                                        <h3>HORIZONTALNA SIGNALIZACIJA</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="content">
                                        <Isvg src={saobracajnaO} />
                                        <div className="heding">
                                        <h3>SAOBRAĆAJNA OPREMA</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="content">
                                        <Isvg src={zastitnaO} />
                                        <div className="heding">
                                        <h3>ZAŠTITNA ČELIČNA OGRADA</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="content">
                                        <Isvg src={projektovanje} />
                                        <div className="heding">
                                        <h3>PROJEKTOVANJE I KONSALTING</h3>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="8" lg="8" md="8">
                                <h1><span>Vertikalna signalizacija</span></h1>
                                <p>Pogledajte neke od naših radova</p>

                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                                ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet.</p>

                                <Row className="no-gutters">
                                    <Col xl="8" md="8" lg="8">
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                             sed diam nonumy eirmod tempor invidunt ut labore et dolore
                                              magna aliquyam erat, sed diam voluptua. At vero eos et
                                          accusam et justo duo dolores et ea rebum. </p>

                                        <p>Stet clita kasd gubergren, no sea takimata sanctus est
                                              Lorem ipsum dolor sit amet.</p>
                                    </Col>
                                    <Col xl="4" md="4" lg="4">
                                        <div className="dot">
                                            <img src={znak} className="img-fluid" />
                                            <img src={dots} className="img-fluid dots" />
                                        </div>
                                    </Col>
                                </Row>

                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                                ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet.</p>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                                erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                                ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet.</p>
                            </Col>
                        </Row>
                    </Container>
                </div>



                <Map {...this.props}></Map>
                <Footer></Footer>
            </>
        );
    }
}