import React, {Component} from 'react';

import company from '../images/company.svg';
import world from '../images/world.svg';
import znak from '../images/znak.svg';
import Isvg from 'react-inlinesvg';
import image from '../images/image.png';
import {
    Col,
    Row,
    Container,
    Button,
} from 'reactstrap';


export default class HowWeWork extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <>
                            <div className="HowWeWork">
                    <div className="content">
                        <Container>
                            <Row>
                                <Col xl="4" lg="4" md="12">
                                    <img src={image} className="img-fluid image" />
                                </Col>
                                <Col xl="8" lg="8" md="12">
                                    <h1><span>Kako i zašto</span> to radimo?</h1>
                                    <p><span>Kao Vaš pouzdan partner, PRO VIA </span>je uvijek spremna da odgovori
                                    na sve Vaše zahtjeve. Prateći važeću zakonsku regulativu i evropske
                                    standarde uz primjenu savremenih materijala, tehnologija i sopstvenog
                                    razvojno programa, nudimo Vam <span>sertifikovane kvalitetne i bezbjedne
                                    proizvode</span> saobraćajne signalizacije i opreme puteva, a sve to
                                    u stvaranju <span>bezbjednijih puteva</span> kao našeg zajedničkog cilja.</p>
                                    <br></br>
                                    <p> <span>VIA VITA!</span> (Put je život!)</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="companyinfo">
                        <Container>
                            <Row>
                                <Col xl="4" lg="4" xs="4">
                                    <Isvg src={company} />
                                    <div className="info">
                                        <h2>2008</h2>
                                        <p>KOMPANIJA OSNOVANA</p>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4"  xs="4">
                                    <Isvg src={world} />
                                    <div className="info">
                                        <h2>5</h2>
                                        <p>ZEMALJA POSLUJEMO</p>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4"  xs="4">
                                    <Isvg src={znak} />
                                    <div className="info">
                                        <h2>54687</h2>
                                        <p>ZNAKOVA POSTAVLJENO</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </div>
            </>
        );
    }
}